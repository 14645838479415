import { useState, useEffect, useContext, Fragment, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import {
  ChevronUpIcon,
  ShoppingCartIcon,
  StarIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import {
  Disclosure,
  RadioGroup,
  Tab,
  Menu,
  Transition,
} from "@headlessui/react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import defaultImage from "../assets/noImage.jpg";
import SlidingCart from "./SlidingCart";
import { Context as AuthContext } from "../Context/AuthContext";
import GeneralContext from "../Context/ProductsContext";
import Alerts from "./Alerts";
import api from "../api/api";
import { CardRecomendado } from "./Card/Produtos/Recomendados/CardRecomendado";
import MyTabs from "./Produto/MyTabs";
import ShareButton from "./ShareButton";
import ShareToWhatsAppButton from "./ShareToWhatsAppButton";
import ReviewCarousel from "./Produto/Review";
import CardProdutos from "./Card/Produtos/CardProdutos";
import { isPromotionActive } from "../utils/ValidatePromotion";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ProductPage = (props) => {
  const [inputTo, setInputTo] = useState("");
  const [isCompany, setIsCompany] = useState(false);
  const { authenticated } = useContext(AuthContext);
  const [item, setItem] = useState({});
  const containerRef = useRef(null); // Referência para a div onde os cards são exibidos
  const containerRecRef = useRef(null); // Referência para a div onde os cards são exibidos
  const [recomendados, setRecomendados] = useState([]);
  const [additionalCards, setAdditionalCards] = useState(0); // Número de cards adicionais a exibir
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [warning, setWarning] = useState({});
  const nameMod = props.nameMod;
  const setShippingInfo = useContext(GeneralContext).setShippingInfo;
  const shippingInfo = useContext(GeneralContext).shippingInfo;
  const selectedRate = useContext(GeneralContext).selectedRate;
  const setselectedRate = useContext(GeneralContext).setselectedRate;
  const itemsInCart = useContext(GeneralContext).itemsInCart;
  const setItemsInCart = useContext(GeneralContext).setItemsInCart;
  const numInCart = useContext(GeneralContext).numInCart;
  const setNumInCart = useContext(GeneralContext).setNumInCart;
  const [tags, setTags] = useState([]);
  const quantity = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [selectedFreteId, setSelectedFreteId] = useState(null);
  const [slideOpen, setSlideOpen] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [thumbnails, setThumbnails] = useState({});

  const openCart = (slide) => {
    setLoaded(slide);
  };
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const onChangeCartSelect = (e) => {
    const oldQuantity = Number(item.quantity); // Convert to Number
    const newQuantity = Number(e.target.id); // Assuming this is correct, convert to Number
    setItem({ ...item, quantity: newQuantity });
  };

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const formatCurrency = (value) => {
    // Convertendo value para um número
    const numberValue = Number(value);

    // Verificando se é um número válido
    if (isNaN(numberValue)) {
      return "R$ 0,00"; // Valor padrão
    }

    // Formatando como moeda no padrão brasileiro (Real)
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numberValue);
  };

  function formatUrl(str) {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/-/g, " ")
      .replace(/\s+/g, "-")
      .trim()
      .toLowerCase();
  }

  useEffect(() => {
    scrollToTop();
    api
      .get(`/products?nameMod=${formatUrl(nameMod)}`)
      .then((res) => {
        const productData = res.data.products[0];

        // Atualizando o estado com os dados do produto
        setItem({ ...productData, quantity: 1 });
      })
      .catch((err) => console.error(err));
  }, [nameMod]); // Incluindo isCompany no array de dependências

  useEffect(() => {
    api
      .get(`/products?take=300`)
      .then((res) => {
        const produtos = res.data.products;

        // Sorteando 40 produtos aleatórios
        const indicesSorteados = [];
        while (indicesSorteados.length < 40) {
          const index = Math.floor(Math.random() * produtos.length);
          if (!indicesSorteados.includes(index)) {
            indicesSorteados.push(index);
          }
        }

        // Mapeando os índices sorteados para os produtos correspondentes
        let produtosRecomendados = indicesSorteados
          .map((index) => produtos[index])
          .filter((produto) => {
            // Verifique se categoryIds é um array antes de usar .some()
            return (
              Array.isArray(produto.categoryIds) &&
              Array.isArray(item.categoryIds) &&
              produto.categoryIds.some((c) => item.categoryIds.includes(c))
            );
          });

        // Filtrando produtos recomendados com base no preço e em isCompany
        produtosRecomendados = produtosRecomendados.filter(
          (produto) =>
            (isCompany && produto.vendorPrice !== 0) ||
            (!isCompany && produto.customerPrice !== 0)
        );

        // Atualizando o estado com os produtos recomendados
        setRecomendados(produtosRecomendados);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }, [item, isCompany]);

  useEffect(() => {
    const fetchThumbnails = async () => {
      const updatedThumbnails = {};

      if (item && Array.isArray(item.imgUrl)) {
        await Promise.all(
          item.imgUrl.map(async (media) => {
            if (!media.startsWith("https")) {
              // Check if it's a video ID
              try {
                const response = await api.get(
                  `https://vimeo.com/api/oembed.json?url=http://vimeo.com/${media}`
                );
                updatedThumbnails[media] = response.data.thumbnail_url;
              } catch (error) {
                console.error(error);
                // Fallback URL in case of error
                updatedThumbnails[
                  media
                ] = `https://i.vimeocdn.com/video/${media}_295x166`;
              }
            } else {
              updatedThumbnails[media] = media; // Directly use the image URL
            }
          })
        );
      } else {
        console.warn("item or item.imgUrl is undefined or not an array:", item);
      }

      setThumbnails(updatedThumbnails);
    };

    fetchThumbnails();
  }, [item]);

  useEffect(() => {
    const containerHeight = containerRecRef?.current?.clientHeight; // Altura da div onde os cards são exibidos
    const cardHeight = 290; // Altura estimada de cada card (ajuste conforme necessário)

    // Calcule o número de cards adicionais com base na altura da div e na altura de cada card
    const additionalCardsCount = Math.floor(
      (containerHeight - 2 * cardHeight) / cardHeight
    );

    // Atualize o estado com o número de cards adicionais
    setAdditionalCards(additionalCardsCount);
  }, [recomendados, containerRecRef]);

  useEffect(() => {
    if (authenticated) {
      api.get("/me").then((res) => {
        setIsCompany(res.data.isCompany);
      });
    }
  }, []);

  useEffect(() => {
    // Adiciona o script da API do Vimeo ao carregar o componente
    const script = document.createElement("script");
    script.src = "https://player.vimeo.com/api/player.js";
    script.async = true;
    document.body.appendChild(script);

    // Remove o script ao desmontar o componente
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const addToCart = (productToAdd, selectedQuantity) => {
    let currentProducts = itemsInCart || [];

    // Flag to check if product exists in the cart
    let productExists = false;

    // Check if the productToAdd is already in the cart
    currentProducts = currentProducts.map((product) => {
      if (product.id === productToAdd.id) {
        // Assuming 'id' is the unique identifier
        productExists = true;
        return { ...product, quantity: selectedQuantity };
      }
      return product;
    });

    // If the product is not in the cart, add it
    if (!productExists) {
      currentProducts.push({
        ...productToAdd,
        quantity: selectedQuantity,
      });
    }

    // Update localStorage and state
    setItemsInCart(currentProducts);
    setNumInCart(
      currentProducts.reduce((acc, product) => acc + selectedQuantity, 0)
    );
    setWarning({ type: "success", message: "Produto adicionado a sacola!" });
    handleOpenSnackbar();
    openCart(true);
  };

  // Function to handle mouse movement over the image
  const handleMouseMove = (e) => {
    const { left, top, width, height } =
      containerRef.current.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    e.target.style.transformOrigin = `${x}% ${y}%`;
  };

  // Reset the transform origin when the mouse leaves
  const handleMouseLeave = (e) => {
    e.target.style.transformOrigin = "center center";
  };

  const selectFrete = (id) => {
    setSelectedFreteId(id);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // animação suave de rolagem
    });
  };

  const handleFrete = () => {
    try {
      api
        .post("me/shipping/calc", {
          to: {
            postal_code: inputTo,
          },
          package: {
            height: 17,
            width: 17,
            length: 21,
            weight: item.weight / 1000,
            insurance_value: 1,
          },
        })
        .then((res) => {
          setShippingInfo(res.data);
        });
    } catch (error) {}
  };

  function FreteInfo(shippingData) {
    return (
      <ul className="divide-y divide-gray-200">
        {shippingData
          ?.filter(
            (shippingOption) =>
              shippingOption.price != null &&
              shippingOption.price !== "" &&
              shippingOption.company.name === "Correios" &&
              shippingOption.name !== "PAC Centralizado" &&
              shippingOption.name !== "SEDEX Centralizado" &&
              shippingOption.name !== "Mini Centralizado"
          )
          .map((shippingOption) => (
            <li
              key={shippingOption.id}
              className={`py-4 px-4 rounded-sm ${
                selectedFreteId === shippingOption.id
                  ? "bg-blue-200"
                  : "bg-white"
              } cursor-pointer hover:bg-blue-100`}
              onClick={() => {
                selectFrete(shippingOption.id);
              }}
            >
              <div className="flex justify-between items-center">
                <div>
                  <h3
                    className={`text-sm font-medium ${
                      selectedFreteId === shippingOption.id
                        ? "text-blue-500"
                        : "text-gray-700"
                    }`}
                  >
                    {shippingOption.name ?? "N/A"} -{" "}
                    {shippingOption.company?.name ?? "N/A"}
                  </h3>
                  <p className="text-xs text-gray-500">
                    Tempo de entrega: {shippingOption.delivery_time ?? "N/A"}{" "}
                    dias
                  </p>
                </div>
                <div className="text-right">
                  <p
                    className={`text-sm font-semibold ${
                      selectedFreteId === shippingOption.id
                        ? "text-blue-600"
                        : "text-gray-900"
                    }`}
                  >
                    {formatCurrency(shippingOption.price)}
                  </p>
                </div>
              </div>
            </li>
          ))}
      </ul>
    );
  }
  useEffect(() => {
    const selectedData = shippingInfo.find(
      (data) => data.id === selectedFreteId
    );
    if (selectedData) {
      setselectedRate(selectedData);
    }
  }, [selectedFreteId, shippingInfo]); // Inclua shippingInfo nas dependências para reagir às suas mudanças também.

  const reviews = [
    {
      photoUrl: "URL_DA_FOTO",
      name: "João Silva",
      rating: 5,
      title: "Excelente produto!",
      description:
        "Estou muito satisfeito com a qualidade do produto. Recomendo!",
    },
    {
      photoUrl: "URL_DA_FOTO",
      name: "Maria Oliveira",
      rating: 4,
      title: "Bom custo-benefício",
      description: "O produto atendeu às minhas expectativas. Entrega rápida!",
    },
    {
      photoUrl: "URL_DA_FOTO",
      name: "Pedro Santos",
      rating: 4,
      title: "Produto Bom!",
      description: "Cumpre o que promete.",
    },
    {
      photoUrl: "URL_DA_FOTO",
      name: "Ana Souza",
      rating: 5,
      title: "Adorei!",
      description: "Produto excelente e entrega antes do prazo.",
    },
    {
      photoUrl: "URL_DA_FOTO",
      name: "Carlos Lima",
      rating: 5,
      title: "Maravilhoso!",
      description: "Maravilhoso!",
    },
    {
      photoUrl: "URL_DA_FOTO",
      name: "Julia Fernandes",
      rating: 4,
      title: "Recomendo",
      description: "Produto bom e entrega dentro do prazo.",
    },
    {
      photoUrl: "URL_DA_FOTO",
      name: "Roberto Almeida",
      rating: 5,
      title: "Muito bom!",
      description: "Superou minhas expectativas. Comprarei novamente.",
    },
  ];

  function calculateDiscountPercentage(originalPrice, discountedPrice) {
    if (originalPrice <= 0) return 0;
    return Math.round(
      ((originalPrice - discountedPrice) / originalPrice) * 100
    );
  }

  const formatPromo = (props) => {
    return isCompany &&
      props.vendorPriceNew > 0 &&
      isPromotionActive(props.discountVendorDueDate) ? (
      <div className="flex flex-col p-2">
        {/* Preço Original com Risco e Etiqueta de Desconto */}
        <div className="relative flex gap-x-2">
          <span className="text-xl font-bold text-gray-400 line-through">
            {`${formatCurrency(props.vendorPrice)}`}
          </span>
          {/* Etiqueta de Desconto */}
          <span className="bg-red-500 bg-[#FFA07A] text-white text-sm p-2 rounded">
            {`${calculateDiscountPercentage(
              props.vendorPrice,
              props.vendorPriceNew
            )}% OFF`}
          </span>
        </div>
        {/* Preço Promocional */}
        <div>
          <span className="text-xl font-bold">
            {`${formatCurrency(props.vendorPriceNew)} `}
          </span>
          <span className="text-sm text-black">{`ou 4x de ${formatCurrency(
            props.vendorPriceNew / 4
          )}`}</span>
        </div>
      </div>
    ) : !isCompany &&
      props.customerPriceNew > 0 &&
      isPromotionActive(props.discountCustomerDueDate) ? (
      <div className="flex flex-col p-2">
        {/* Preço Original com Risco e Etiqueta de Desconto */}
        <div className="relative flex gap-x-2">
          <span className="text-xl font-bold text-gray-400 line-through">
            {`${formatCurrency(props.customerPrice)}`}
          </span>
          {/* Etiqueta de Desconto */}
          <span className="bg-red-500 bg-[#FFA07A] text-white text-sm p-2 rounded">
            {`${calculateDiscountPercentage(
              props.customerPrice,
              props.customerPriceNew
            )}% OFF`}
          </span>
        </div>
        {/* Preço Promocional */}
        <div>
          <span className="text-xl font-bold">
            {`${formatCurrency(props.customerPriceNew)} `}
          </span>
          <span className="text-sm text-black">{` ou 4x de ${formatCurrency(
            props.customerPriceNew / 4
          )}`}</span>
        </div>
      </div>
    ) : (
      <div className="p-2">
        <span className="text-xl font-bold ">{`${formatCurrency(
          isCompany ? props.vendorPrice : props.customerPrice
        )}`}</span>
        <span className="text-sm text-black">{` ou 4 x de ${formatCurrency(
          isCompany ? props.vendorPrice / 4 : props.customerPrice / 4
        )}`}</span>
      </div>
    );
  };

  return (
    <div className="bg-white lg:h-full xs:pt-[167px] sm:pt-[152px] md:pt-[177px]">
      <div className="mx-auto  2xl:py-10 sm:py-24 sm:px-6 w-full xl:px-40">
        <div className="flex">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Link underline="hover" color="inherit" href="/produtos">
              Produtos
            </Link>
            <Typography color="black">{item.name}</Typography>
          </Breadcrumbs>
        </div>

        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-2 xl:gap-x-0">
          {/* Image gallery */}
          <div className="flex flex-col">
            <Tab.Group
              as="div"
              className="flex flex-col-reverse md:flex-row-reverse"
            >
              {/* Media Viewer */}
              <Tab.Panels className="w-full aspect-w-1 aspect-h-1 flex justify-center">
                {item?.imgUrl?.map((media) => {
                  const isVideo = !media.startsWith("http");
                  const videoUrl = `https://player.vimeo.com/video/${media}?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&controls=1&showinfo=0&rel=0&modestbranding=0&loop=1`;

                  return (
                    <Tab.Panel
                      key={media}
                      ref={containerRef}
                      onMouseMove={handleMouseMove}
                      onMouseLeave={handleMouseLeave}
                      className="overflow-hidden flex"
                      style={{
                        width: "100%",
                        height: "auto",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {isVideo ? (
                        <div className="flex justify-center align-middle items-center w-2/3 md:w-full h-[300px] xs:h-[400px] 2md:h-[450px] lg:h-[440px] 2xl:h-[700px] p-4">
                          <iframe
                            src={videoUrl}
                            allow="autoplay; encrypted-media"
                            className="w-full h-full"
                            title="Video"
                          />
                        </div>
                      ) : (
                        <img
                          src={media}
                          alt="Media"
                          className="h-full w-full object-fit object-center transform transition-transform duration-300 p-4"
                        />
                      )}
                    </Tab.Panel>
                  );
                })}
              </Tab.Panels>

              {/* Media Selector */}
              <div className="mx-2 mt-6 w-full flex md:w-24 max-w-[300px] xs:max-w-sm lg:max-w-none">
                <Tab.List className="flex flex-row gap-4 md:flex-col md:gap-6">
                  {item?.imgUrl?.map((media) => {
                    const isVideo = !media.startsWith("https");
                    const thumbnailUrl = thumbnails[media] || media;

                    return (
                      <Tab
                        key={media}
                        className="relative flex h-10 w-10 xs:h-16 xs:w-16 md:h-24 md:w-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none"
                      >
                        {({ selected }) => (
                          <>
                            <span className="sr-only"> {media} </span>
                            <span className="absolute inset-0 overflow-hidden rounded-md">
                              <img
                                src={thumbnailUrl}
                                alt="Media Thumbnail"
                                className="h-full w-full object-cover object-center transform transition-transform duration-300 hover:scale-[1.2]"
                              />
                            </span>
                            <span
                              className={classNames(
                                selected ? "ring-blue-500" : "ring-transparent",
                                "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </Tab>
                    );
                  })}
                </Tab.List>
              </div>
            </Tab.Group>

            {/* Product Description */}
            {item && item.description && (
              <div className="mt-12 hidden md:flex">
                <MyTabs Desc={item.description} Rec={containerRecRef} />
              </div>
            )}
          </div>

          {/* Product info */}
          <div className="sm:mt-16 lg:mt-0  max-w-[500px] space-y-4 mx-auto px-auto">
            <h1 className="text-2xl md:text-3xl font-extrabold tracking-tight text-gray-900 text-center md:text-left">
              {item.name}
            </h1>
            <div className="space-y-2">
              <div className="flex items-center justify-center md:justify-normal space-x-2">
                <ShareButton />
                <ShareToWhatsAppButton product={item} />
              </div>
              {!isCompany && item.customerPrice === 0 ? (
                <span className="text-md font-bold text-red">
                  Produto disponível apenas para salão
                </span>
              ) : isCompany && item.vendorPrice === 0 ? (
                <span className="text-md font-bold">Produto indisponível</span>
              ) : (
                formatPromo(item)
              )}
            </div>

            <div className="text-sm text-gray-500">{item.shortDesc}</div>

            <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
              <Menu as="div" className="relative inline-block text-left">
                <Menu.Button className="inline-flex w-full justify-between rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none">
                  <span>Quantidade: {selectedQuantity}</span>
                  <ChevronDownIcon
                    className="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-75"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Menu.Items className="absolute left-1 right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {quantity.map((quantity) => (
                        <Menu.Item key={quantity}>
                          {({ active }) => (
                            <p
                              onClick={() => setSelectedQuantity(quantity)}
                              className={`block px-4 py-2 text-sm ${
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700"
                              }`}
                            >
                              {quantity}
                            </p>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              <button
                onClick={() => addToCart(item, selectedQuantity)}
                className={`mt-4 sm:mt-0 inline-flex items-center justify-center rounded-md border border-transparent ${
                  isCompany && item.vendorPrice === 0
                    ? "disabled:opacity-50 cursor-not-allowed bg-gray-500"
                    : !isCompany && item.customerPrice === 0
                    ? "disabled:opacity-50 cursor-not-allowed bg-gray-500"
                    : "hover:bg-blue-700"
                }  px-1 xl:px-5 py-2 text-base font-medium bg-blue-600 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-black-500 focus:ring-offset-2`}
                disabled={
                  (isCompany && item.vendorPrice === 0) ||
                  (!isCompany && item.customerPrice === 0)
                }
              >
                <ShoppingCartIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                Adicionar à sacola
              </button>
            </div>

            <div className="mt-6">
              <div className="flex justify-between w-full bg-gray-100 px-4 py-2 text-sm font-medium text-blue-700 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75 rounded-t-lg">
                <p>Calcular Frete</p>
              </div>
              <div className="px-4 pb-2 text-sm text-black bg-gray-100 rounded-b-lg">
                <input
                  type="text"
                  value={inputTo}
                  onChange={(e) => setInputTo(e.target.value)}
                  placeholder="CEP"
                  className="w-full rounded-md border-gray-50 px-4 py-2 focus:border-blue-500 focus:ring-blue-200"
                />
                <button
                  onClick={handleFrete}
                  className="mt-2 w-full rounded-md bg-blue-500 px-5 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-black-500 focus:ring-offset-2"
                >
                  Calcular
                </button>
                {shippingInfo?.length > 0 && FreteInfo(shippingInfo)}
              </div>
            </div>
            {/* Product Description Mobile */}
            {item && item.description && (
              <div className="mt-12 md:hidden">
                <MyTabs Desc={item.description} Rec={containerRecRef} />
              </div>
            )}
            <div className="pt-12">
              <h2 id="details-heading" className="sr-only">
                Detalhes Adicionais
              </h2>

              <h3 className="font-bold px-8">Compre junto</h3>
              {item && (
                <div ref={containerRef} className="flex flex-col divide-y">
                  {recomendados
                    .slice(
                      0,
                      Math.max(
                        2,
                        2 + additionalCards >= 2 ? 2 + additionalCards : 2
                      )
                    )
                    .map((recomendado, index) => (
                      <CardRecomendado
                        key={index}
                        handleOpenSnackbar={handleOpenSnackbar}
                        setWarning={setWarning}
                        index={index}
                        isCompany={isCompany}
                        produto={item}
                        recomendado={recomendado}
                        openCart={openCart}
                      />
                    ))}
                </div>
              )}

              <Alerts
                open={openSnackbar}
                handleClose={handleCloseSnackbar}
                type={warning.type}
                message={warning.message}
              />
              <SlidingCart
                openCart={openCart}
                slideOpen={slideOpen}
                setSlideOpen={setSlideOpen}
                loaded={loaded}
              />
            </div>
          </div>
        </div>

        <div className="w-full flex justify-center my-6">
          <CardProdutos title="Veja Também" data={recomendados} />
        </div>

        <div className="w-full">
          <h1 className="text-2xl font-semibold mb-4 text-center">
            Área de Avaliações
          </h1>

          <div className="p-4">
            <ReviewCarousel reviews={reviews} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
